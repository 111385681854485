import { META } from './meta'

export default {
  methods: {
    getLanguageByCode (code) {
      return window._.find(this.languages, o => { return o.name === code })
    }
  },
  computed: {
    languages () {
      let languageList = [
        {
          displayName: 'None',
          versions: [],
          name: 'none'
        }
      ]

      for (let key in META) {
        let lang = META[key]
        if (lang.isCompile === true || lang.isCompile === false) {
          languageList.push({
            displayName: lang.langDisplayName,
            versions: lang.versions,
            name: lang.language,
            aceCode: lang.aceCode
          })
        }
      }

      return languageList
    }
  }
}
